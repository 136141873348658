import store from '../store'

// export function FilterPermissions (slug, canSlug) {
//   let data = JSON.parse(localStorage.getItem('_Data11'))
//   if (data.isAdmin) {
//     return true
//   } else {
//     if (slug === undefined || canSlug === undefined) {
//       return false
//     } else {
//       if (slug === 'hrmSettings') {
//         let filerData = []
//         filerData = store.getters.permission.filter(val => {
//           return val.slug === 'settings'
//         })
//         if (filerData.length > 0) {
//           if (filerData[0].subMenuPermissions !== null) {
//             let isViewAllAreFalse = false
//             for (let sub = 0; sub < filerData[0].subMenuPermissions.length; sub++) {
//               if (filerData[0].subMenuPermissions[sub].isViewAll === true) {
//                 isViewAllAreFalse = true
//                 break
//               } else {
//                 isViewAllAreFalse = false
//               }
//             }
//             return isViewAllAreFalse
//           }
//         }
//       } else {
//         let filerData = []
//         filerData = store.getters.permission.filter(val => {
//           return val.slug === slug
//         })
//         if (filerData.length === 0) {
//           store.getters.permission.forEach(val => {
//             if (val.subMenuPermissions !== null) {
//               filerData = val.subMenuPermissions.filter(v => {
//                 return v.slug === slug
//               })
//             }
//           })
//         }
//         if (filerData.length > 0) {
//           return filerData[0][canSlug]
//         }
//       }
//     }
//   }
// }

export function FilterPermissions (slug) {
  let data = store.getters.permission
  if (data.permissionList === undefined) {
    return store.dispatch('GetEmployeePermission', false).then(
      () => checkingPermissions(data, slug)
    )
  } else {
    return checkingPermissions(data, slug)
  }
}

function checkingPermissions (data, slug) {
  if (data.isAdmin) {
    return true
  } else if (slug === '') {
    return true
  } else {
    if (slug === undefined) {
      return false
    } else if (data.permissionList !== undefined) {
      let isPermissionAvailable = data.permissionList.find(val => {
        return val.entityPermissionsSlug === slug
      })
      console.log('isPermissionAvailable', isPermissionAvailable)
      return isPermissionAvailable !== undefined
    }
    return false
  }
}
export function FilterForSettingsRelatedPermission (settingsSlug) {
  let permission = store.getters.permission.filter(val => {
    return val.slug === settingsSlug
  })
  if (permission.length > 0) {
    let showIcon = false
    for (let i = 0; i < permission[0].subMenuPermissions; i++) {
      if (permission[0].subMenuPermissions[i].isViewAll) {
        showIcon = true
        break
      }
    }
    return showIcon
  }
  return false
}

export function FilterProjectPermissions (slug) {
  let data = store.getters.projectPermission.permissionList
  let empData = store.getters.permission
  if (empData.isAdmin) {
    return true
  }
  console.log('slug', slug)
  if (data.permissionList) {
    if (slug === '') {
      return true
    } else {
      if (slug === undefined) {
        return false
      } else if (data.permissionList !== undefined) {
        let isPermissionAvailable = data.permissionList.find(val => {
          return val.entityPermissionsSlug === slug
        })
        console.log('isPermissionAvailable', isPermissionAvailable)
        return isPermissionAvailable !== undefined
      }
      return false
    }
  } else {
    return false
  }
}
